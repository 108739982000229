import React, { useEffect } from "react";
import FreedoFeature from "./FreedoFeatures";
import FreedoRentingFleet from "./FreedoRentingFleet";
import FreedoPictureSpeak from "./FreedoPictureSpeak";
import FreedoCounter from "./FreedoCounter";
import HomePageCarousal from "./HomePageCarousal";
import FAQs from "./FAQs";
import { useDispatch } from "react-redux";
import { getCityListMasters } from "../../redux/actions";
import DATA from "../../locales/en.json";
import { NavLink } from "react-router-dom";
import { FirebaseEvents } from "../../utills/firebaseEvents";
import { EventApi } from "../../utills/eventApi";
import { Helmet } from "react-helmet";

const HomeScreen = () => {
  const { FBLink, InstaLink, LinkdinLink, whatsappLink } = DATA.contact;
  const dispatch = useDispatch();

  ///facebook and firebase analytics service call
  const analyticsEventCall = () => {
    console.log("HomeScreenLoaded_Overhaul", {});
    FirebaseEvents("HomeScreenLoaded_Overhaul", {});
    const data = {
      data: [
        {
          event_name: "PageView",
          event_time: new Date(),
          event_source_url: window.location.href,
          user_data: {
            client_ip_address: "1.2.3.4",
            client_user_agent: "*",
          },
        },
      ],
    };
    EventApi(data);
  };

  useEffect(() => {
    dispatch(getCityListMasters({ type: "GetCityList", payload: {} }));
    analyticsEventCall();
  }, []);

  return (
    <>
      <Helmet>
      <title>Freedo: Bike Rentals | Two Wheelers Rentals App</title>
        <meta
          name="title"
          content="Freedo: Bike Rentals | Two Wheelers Rentals App"
        />
        <meta
          name="description"
          content="Book your ride with our highest-rated two-wheeler rental app, Freedo Rentals. We have got you covered with our daily, weekly, and monthly rental plans at budget."
        />
        <meta name="url" content="https://freedo.rentals/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://freedo.rentals/" />
        <meta
          property="og:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="og:description"
          content="Book your ride with our highest-rated two-wheeler rental app, Freedo Rentals. We have got you covered with our daily, weekly, and monthly rental plans at budget."
        />
        <meta property="og:title" content="Freedo Rentals" />
        {/* <meta
            property="og:image"
            content="https://herowebuatfreedo.azurewebsites.net/logo.jpg"
          /> */}
        <meta property="twitter:url" content="https://freedo.rentals/" />
        <meta
          property="twitter:title"
          content="Bike Rentals in Noida, Delhi NCR | Freedo Rentals"
        />
        <meta
          property="twitter:description"
          content="Freedo offers best two-wheelers for rent! Find Hero Splendor, Activa,  Sports Bikes & Scooters in Delhi, Noida,Gurgaon, Bangalore, Jaipur, Vadodara, Vijayawada & more! Daily, weekly and monthly options available. Discover the city on your terms!"
        />
        <meta
          name="google-site-verification"
          content="Ecn173VGRFej3lS_l0tyocsskiFHgWCgZRJrIsi14ts"
        />
        <meta
          name="facebook-domain-verification"
          content="a1oc8hi8atd3ijbxz2v0vatkpo3cfz"
        />
        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Freedo Rentals",
            alternateName: "Freedo",
            url: "https://freedo.rentals/",
            logo: "https://freedo.rentals/static/media/freedo-logo.fe30d79770ed859f6769.png",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+91 9599819940",
              contactType: "customer service",
              contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/freedorentals/",
              "https://www.instagram.com/freedorentals/",
              "https://www.linkedin.com/company/freedorentals/",
            ],
          })}
        </script>

        <script
          src="./seoText.json"
          id="seoData"
          type="application/json"
        ></script>
      </Helmet>
      <div className="fixed-social-tray">
        <div id="fixed-social">
          <div>
            <a href={whatsappLink} className="fixed-whatsapp" target="_blank">
              <i className="fab fa-whatsapp"></i> <span>WhatsApp</span>
            </a>
          </div>
          <div>
            <a href={FBLink} className="fixed-facebook" target="_blank">
              <i className="fab fa-facebook"></i> <span>Facebook</span>
            </a>
          </div>
          <div>
            <a href={LinkdinLink} className="fixed-linkedin" target="_blank">
              <i className="fab fa-linkedin"></i> <span>LinkedIn</span>
            </a>
          </div>
          <div>
            <a href={InstaLink} className="fixed-instagram" target="_blank">
              <i className="fab fa-instagram"></i> <span>Instagram</span>
            </a>
          </div>
        </div>
      </div>
      <HomePageCarousal />
      <FreedoFeature />
      <FreedoRentingFleet />
      <FreedoPictureSpeak />
      <FreedoCounter />
      <FAQs />
    </>
  );
};

export default HomeScreen;

import React from "react";
import en from "../../locales/en.json";
import { commmaSeparator } from "../../utills/helper";
const BillingPrice = ({ priceDetail, classname, heading = true }: any) => {
  const { checkout } = en;
  const {
    packageCharge,
    securityAmt,
    discount,
    additionalHelmet,
    deliverCharge,
    sgst,
    cgst,
    pickUpDeliveryCharges,
    dropOffDeliveryCharges,
    totalAmount,
  } = priceDetail;
  return (
    <div className="billing-wrap">
      <div className="table-responsive">
        <table className={`table table-borderless table-sm ${classname}`}>
          {heading && (
            <thead>
              <tr>
                <th scope="col">{checkout.billingDetails}</th>
                <th scope="col"></th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <td>{checkout.packageCharge}</td>
              <td>{`₹ ${commmaSeparator((packageCharge + sgst + cgst) || 0)}`}</td>
            </tr>
            <tr>
              <td>{checkout.securityDeposite}</td>
              <td>{`₹ ${commmaSeparator((securityAmt) || 0)}`}</td>
            </tr>
            <tr>
              <td>{checkout.additionalHelmet}</td>
              <td>{`₹ ${(additionalHelmet) || 0}`}</td>
            </tr>

            <tr>
              <td>{checkout.homeDeliveryCharge}</td>
              <td>{`₹ ${commmaSeparator((pickUpDeliveryCharges) || 0)}`}</td>
            </tr>
            {dropOffDeliveryCharges > 0 && (
              <tr>
                <td>{checkout.dropOffdeliveryCharge}</td>
                <td>{`₹ ${commmaSeparator((dropOffDeliveryCharges) || 0)}`}</td>
              </tr>
            )}
            {discount >0 && (
              <tr>
                <td>{checkout.discount}</td>
                <td>-{`₹ ${commmaSeparator((discount) || 0)}`}</td>
              </tr>
            )}
            <tr>
              <td>{checkout.totalAmount}</td>
              <td>{`₹ ${commmaSeparator((totalAmount) || 0)}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BillingPrice;

import React, { useState } from "react";
import {
  targetLogo,
  slide1,
  slide3,
  slide4,
  sliderVideo,
  bang,
} from "../../assets";
import en from "../../locales/en.json";
import SelectCityModal from "./SelectCityModal";
import { getLocalStorageData } from "../../utills/helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, FC } from "react";
import { actions } from "../../redux";
import { RootState } from "../../redux/reducers";

import JourneyModal from "./JourneyModal";
import { LocalStorage } from "../../constant/enum";
import { Routes } from "../../constant/routeContants";
import { Link } from "react-router-dom";
import { toggleCityModal, toggleJourneyModal } from "../../redux/actions";
import { FirebaseEvents } from "../../utills/firebaseEvents";

const { homePageCarousal } = en.home;

export const CauraselData = {
  IsSuccess: true,
  ResponseStatusCode: 200,
  Message: ["Record fetch successfully"],
  Result: [
    {
      id: 100,
      header: "Two Wheelers Rentals ",
      body_text: "Freedom To",
      body_text_1: "Move",
      body_para: "Presenting the safe, reliable and affordable",
      body_para_1: "mobility solution",
      launch_date: "2023-06-01T00:00:00.000Z",
      imageData: {
        image: [
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/bengaluru-cityicon-1676287505431.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T115600Z&X-Amz-Expires=300&X-Amz-Signature=3c00353d91637d88a822a33cfd6a1959feff7f2ce091130dbfcd019add327821&X-Amz-SignedHeaders=host",
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/noida-cityicon-1676289349743.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T120109Z&X-Amz-Expires=300&X-Amz-Signature=263770687e989b270524f10b986a93169251317d9a91ea9d301bb749a33064df&X-Amz-SignedHeaders=host",
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/chennai-cityicon-1675684378617.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T120229Z&X-Amz-Expires=300&X-Amz-Signature=fcd6758165f25e265f4f3b573550b4d12611039d5a74867efc2598dcaaba2c4c&X-Amz-SignedHeaders=host",
        ],
        video: [
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/nainital%20uttarakhand-cityicon-1676286988846-cityicon-1679483631912.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T115600Z&X-Amz-Expires=300&X-Amz-Signature=cddd301e7f02534cd27e495390da05fcde08517a409f216ff34d6508c9c9ffdc&X-Amz-SignedHeaders=host",
        ],
      },
    },
    {
      id: 101,
      header: "Two Wheelers Rentals ",
      body_text: "The New Way",
      body_text_1: "To Own ",
      body_para: "Presenting the safe, reliable and affordable",
      body_para_1: "mobility solution",
      launch_date: "2023-06-01T00:00:00.000Z",
      imageData: {
        image: [
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/bengaluru-cityicon-1676287505431.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T115600Z&X-Amz-Expires=300&X-Amz-Signature=3c00353d91637d88a822a33cfd6a1959feff7f2ce091130dbfcd019add327821&X-Amz-SignedHeaders=host",
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/noida-cityicon-1676289349743.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T120109Z&X-Amz-Expires=300&X-Amz-Signature=263770687e989b270524f10b986a93169251317d9a91ea9d301bb749a33064df&X-Amz-SignedHeaders=host",
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/chennai-cityicon-1675684378617.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T120229Z&X-Amz-Expires=300&X-Amz-Signature=fcd6758165f25e265f4f3b573550b4d12611039d5a74867efc2598dcaaba2c4c&X-Amz-SignedHeaders=host",
        ],
        video: [
          "https://common-files-uat-v1.s3.ap-south-1.amazonaws.com/city-images/nainital%20uttarakhand-cityicon-1676286988846-cityicon-1679483631912.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWTGPUWKUWDMTHJQA%2F20230530%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230530T115600Z&X-Amz-Expires=300&X-Amz-Signature=cddd301e7f02534cd27e495390da05fcde08517a409f216ff34d6508c9c9ffdc&X-Amz-SignedHeaders=host",
        ],
      },
    },
  ],
  Error: "",
};

const HomePageCarousal = () => {
  const dispatch = useDispatch();
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const { carouselData } = useSelector((state: RootState) => state.homeReducer);
  useEffect(() => {
    dispatch(
      actions.getCarouselData({ type: "GetCarouselDetails", payload: {} })
    );
  }, []);

  return (
    <section className="slider-wrapper">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        {/* <div className="carousel-indicators">
          {carouselData.map((_data: any, index: number) => {
            return (
              <button
                key={_data.id}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className="active"
                aria-current="true"
                aria-label={"Slide " + (index + 1)}
              ></button>
            );
          })}
        </div> */}
        <div className="carousel-inner">
          <CarousalItem carouselData={carouselData} />
        </div>
      </div>
    </section>
  );
};

const CarousalItem = (props: any) => {
  return (
    <>
      {props.carouselData?.slice(0, 1)?.map((_details: any, index: number) => {
        return (
          <div
            className={"carousel-item " + (index === 0 ? " active " : "")}
            data-bs-interval="10000"
            key={index}
          >
            <div className="container-fluid">
              <div className="main-content-wrapper main-content-wrapper-slider">
                <div className="row slider-content">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <h5>{_details.header}</h5>
                    <h1>
                      {_details.body_text}
                      <br />
                      {_details.body_text_1}
                    </h1>
                    <p>
                      {_details.body_para}
                      <br /> {_details.body_para_1}
                    </p>
                    {index == 0 ? (
                      <LocationModal />
                    ) : (
                      <Link
                        to={Routes.RENT}
                        className="freedo-primary-button rounded-pill"
                      >
                        Explore our fleet
                        <i className="fa-solid fa-right-long"></i>
                      </Link>
                    )}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="row justify-content-end slider-right-wrap">
                      <div className="slider-img-wrap">
                        <img
                          src={slide1}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = slide1;
                          }}
                        />
                        <img
                          src={sliderVideo}
                          className="slide-2"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = sliderVideo;
                          }}
                        />
                      </div>
                      <div className="slider-img-wrap">
                        <img
                          src={slide3}
                          className="slide-3"
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = slide3;
                          }}
                        />
                        <img
                          src={slide4}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = slide4;
                          }}
                        />
                      </div>

                      <div className="top-content rounded-pill">
                        {homePageCarousal.HassleFreeRentalSolution}
                        <i className="fa-solid fa-circle-check"></i>
                      </div>
                      <div className="bottom-content rounded-pill">
                        {homePageCarousal.AffordableRentalRides}
                        <i className="fa-solid fa-circle-check"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const LocationModal: FC = () => {
  const dispatch = useDispatch();
  const selectCityBtn = document.getElementById("cityModalBtn");
  const cityData = getLocalStorageData(LocalStorage.userCity);
  const [userCity, setUserCity] = useState<any>(cityData);
  const [isJourneyCity, setIsJourneyCity] = useState<boolean>(false);
  console.log("898998", isJourneyCity)

  const handleCityChange = (newCity: any) => {
    if (newCity) {
      setUserCity(newCity);
    }
    if (isJourneyCity) {
      const elem = document.getElementById("btn-journy-trigger");
      if (elem) {
        elem.click();
        // setIsJourneyCity(false);
      }
    }
  };

  useEffect(() => {
    if (selectCityBtn && !cityData) {
      selectCityBtn.click();
    }
  }, [selectCityBtn]);

  
  return (
    <div>
      <form className="locate-me-model-wrap">
        <div className="form-check location-wrap">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <button className="freedo-outline-btn location-btn" id="cityModalBtn"
                type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                onClick={() => dispatch(toggleCityModal(true))}>
                <i className="fa-solid fa-location-dot me-2"></i>
                {cityData ? cityData.city_name : homePageCarousal.LocateMe}
              </button>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
            <span className="shine"></span>
              <button className="freedo-outline-btn search-btn"
                id="btn-journy-trigger"
                data-bs-toggle="modal"
                data-bs-target="#journyModal"
                // value={homePageCarousal.SearchVehicle}
                onClick={(e: any) => {
                  e.preventDefault()
                  setIsJourneyCity(true)
                  FirebaseEvents("HomepageSearchVehicle", {});
                }} >Search Vehicle
                
              </button>
              <SelectCityModal handleChange={handleCityChange} />
            </div>
          </div>
        </div>
      </form>
      <JourneyModal
        isJourneyCity={isJourneyCity}
        setIsJourneyCity={setIsJourneyCity}
      />
    </div>
  );
};

export default HomePageCarousal;
